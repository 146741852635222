import React, { useEffect } from 'react'
import Home from './Home';
const Main = () => {

  useEffect(
    () => {
    },[]
  )

  return (
    <React.Fragment>
      <Home/>
    </React.Fragment>
  );
}

export default Main;
